.legend ul {
  list-style: none;
  padding: 0;
}
.legend-swatch {
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 8px;
}
